import { render, staticRenderFns } from "./Fiche.vue?vue&type=template&id=b6cb9060&scoped=true&"
import script from "./Fiche.vue?vue&type=script&lang=js&"
export * from "./Fiche.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6cb9060",
  null
  
)

export default component.exports